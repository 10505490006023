import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
const state = {
  isLanguage: "1",
};
const mutations = {
  setisLanguage(state, data) {
    state.isLanguage = data;
  },
};
export default new Vuex.Store({ state, mutations });
