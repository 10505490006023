<template>
  <div class="div">
    <div class="box">
      <div class="logo">
        <router-link to="/"><img src="@/assets/img/index/logo.png" /></router-link>
      </div>
      <ul class="list">
        <li class="tab">
          <a href="javascript:;">{{ text1 }}</a>
          <div class="hide">
            <router-link to="/about/synopsis">{{ text5 }}</router-link>
            <router-link to="/about/development">{{ text4 }}</router-link>
            <router-link to="/about/logo">{{ text19 }}</router-link>
            <router-link to="/about/culture">{{ text3 }}</router-link>
            <router-link to="/about/team">{{ text2 }}</router-link>
          </div>
        </li>
        <li class="tab">
          <a href="javascript:;">{{ text6 }}</a>
          <div class="hide">
            <router-link to="/product/pipeline">{{ text7 }}</router-link>
          </div>
        </li>
        <li class="tab">
          <a href="javascript:;">{{ text9 }}</a>
          <div class="hide">
            <router-link to="/platform/step">{{ text10 }}</router-link>
            <router-link to="/platform/biology">{{ text11 }}</router-link>
            <router-link to="/platform/cmc">{{ text12 }}</router-link>
          </div>
        </li>
        <li class="tab">
          <!-- <router-link to="/investment">{{ text13 }}</router-link> -->
          <a href="javascript:;">{{ text13 }}</a>
        </li>
        <li class="tab">
          <router-link to="/New/News">{{ text14 }}</router-link>
        </li>
        <li class="tab">
          <a href="javascript:;">{{ text15 }}</a>
          <div class="hide">
            <router-link to="/manpower">{{ text15_2 }}</router-link>
            <a href="https://www.liepin.com/company-jobs/9996623/" target="_blank">{{ text15_3 }}</a>
          </div>
        </li>
        <li class="tab">
          <router-link to="/contact">{{ text16 }}</router-link>
        </li>
        <li class="tab taggle">
          {{ text17 }}
          <div class="hide">
            <p @click="changeLang('en')">English</p>
            <p @click="changeLang('cn')">中文</p>
          </div>
        </li>
      </ul>

      <div class="hide">
        <div>
          <a href="javascript:;" class="hide_left" @click="toggle"> </a>

          <a href="./index.html" class="hide_logo"><img src="@/assets/img/index/logo.png" alt="" /></a>
          <div class="hide_taggle lang open">
            {{ text17 }}
            <div class="hide_taggle_box">
              <p @click="changeLang('en')">English</p>
              <p @click="changeLang('cn')">中文</p>
            </div>
          </div>
        </div>
      </div>

      <ul class="list_hide" id="list_hide" v-show="isShow">
        <li class="tab">
          <a href="javascript:;">{{ text1 }}</a>
          <router-link class="nav_two" to="/about/synopsis">{{ text5 }}</router-link>
          <router-link class="nav_two" to="/about/development">{{ text4 }}</router-link>
          <router-link class="nav_two" to="/about/logo">{{ text19 }}</router-link>
          <router-link class="nav_two" to="/about/culture">{{ text3 }}</router-link>
          <router-link class="nav_two" to="/about/team">{{ text2 }}</router-link>
        </li>
        <li class="tab">
          <a href="javascript:;">{{ text6 }}</a>
          <router-link class="nav_two" to="/product/pipeline">{{ text7 }}</router-link>
        </li>
        <li class="tab">
          <a href="javascript:;">{{ text9 }}</a>
          <router-link class="nav_two" to="/platform/step">{{ text10 }}</router-link>
          <router-link class="nav_two" to="/platform/biology">{{ text11 }}</router-link>
          <router-link class="nav_two" to="/platform/cmc">{{ text12 }}</router-link>
        </li>
        <li class="tab">
          <!-- <router-link to="/investment">{{ text13 }}</router-link> -->
          <a href="javascript:;">{{ text13 }}</a>
        </li>
        <li class="tab">
          <router-link to="/New/News">{{ text14 }}</router-link>
          <!-- <router-link class="nav_two" to="/New/Media">{{ text18 }}</router-link> -->
        </li>
        <li class="tab">
          <a href="javascript:;">{{ text15 }}</a>
          <router-link class="nav_two" to="/platform/step">{{ text15_2 }}</router-link>
          <a class="nav_two" href="https://futuregen.gllue.com/portal">{{ text15_3 }}</a>
        </li>
        <li class="tab">
          <router-link to="/contact">{{ text16 }}</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
let language = localStorage.getItem("language") || "cn";
import { mapState } from "vuex";
export default {
  data() {
    switch (language) {
      case "cn":
        return {
          text1: "关于我们",
          text2: "高管团队",
          text3: "企业文化",
          text4: "发展历程",
          text5: "企业简介",
          text6: "产品中心",
          text7: "产品管线",
          text8: "产品信息",
          text9: "平台技术",
          text10: "STEP平台",
          text11: "生物功能分析平台",
          text12: "CMC生产平台",
          text13: "投资人关系",
          text14: "新闻中心",
          text15: "职业发展",
          text15_2: "员工生活",
          text15_3: "招贤纳士",
          text16: "联系我们",
          text17: "中/EN",
          text18: "媒体报道",
          text19: "LOGO 意涵",
          isShow: false,
        };
        break;
      case "en":
        return {
          text1: "About",
          text2: "Management Team",
          text3: "Corporate Culture",
          text4: "Corporate History",
          text5: "Corporate Profile",
          text6: "Products",
          text7: "Pipeline",
          text8: "Product Information",
          text9: "Platform",
          text10: "STEP Platform",
          text11: "Biological Function Analysis Platform",
          text12: "CMC Production Platform",
          text13: "Investor Relations",
          text14: "Media",
          text15: "Careers",
          text15_2: "Employee Life",
          text15_3: "Job Opportunities",
          text16: "Contact",
          text17: "中/EN",
          text18: "Media Report",
          text19: "LOGO Connotation",
          isShow: false,
        };
        break;
    }
  },
  computed: {
    ...mapState(["isLanguage"]),
  },
  methods: {
    changeLang(flag) {
      language = flag;
      localStorage.setItem("language", language);
      location.reload();
    },
    toggle: function () {
      this.isShow = !this.isShow;
    },
  },
};
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
}
body {
  overflow-x: hidden;
}
a {
  -webkit-tap-highlight-color: transparent;
}
.div {
  position: sticky;
  top: 0;
  width: 100vw;
  height: 130px;
  z-index: 999;
  color: #333;
  background: #fff;
  .box {
    width: 100%;
    max-width: 1300px;
    height: 130px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    -webkit-tap-highlight-color: transparent;
    .logo {
      a {
        img {
          width: 162px;
          height: 52px;
        }
      }
    }
    .list {
      width: 80%;
      list-style: none;
      font-size: 20px;
      display: flex;
      justify-content: space-around;
      li {
        cursor: pointer;
        margin: 0 1%;
        position: relative;
        height: 130px;
        line-height: 130px;
        a {
          color: #203864;
          text-decoration: none;
          white-space: nowrap;
        }
        a:hover {
          font-weight: bold;
        }
        div {
          position: absolute;
          top: 100%;
          width: max-content;
          text-align: left;
          padding: 15px;
          font-size: 18px;
          background-color: #fff;
          a {
            display: block;
            padding: 6px;
          }
          p {
            line-height: 30px;
          }
        }
        .hide {
          display: none;
          z-index: 99;
          a {
            line-height: 30px;
          }
        }
      }
      :hover .hide {
        display: block;
      }
    }
    .taggle {
      position: relative;
      font-size: 16px;
      text-align: center;
      cursor: pointer;
      .taggle_box {
        display: none;
        position: absolute;
        top: 96%;
        left: 0%;
        width: 100px;
        border: 1px solid rgba(51, 51, 51, 0.288);
        background-color: #fff;
      }
    }
    .taggle:hover {
      .taggle_box {
        display: block;
      }
    }
    .hide {
      display: none;
    }
  }
}
@media screen and (max-width: 750px) {
  .div {
    position: sticky;
    top: 0px;
    width: 100vw;
    height: 56px;
    .box {
      height: 56px;
      .logo {
        display: none;
      }
      .list {
        display: none;
      }
      .taggle {
        display: none;
      }
      .hide {
        position: sticky;
        top: 0;
        display: block;
        div {
          width: 100vw;
          display: flex;
          justify-content: space-around;
          align-items: center;
          .hide_left {
            position: relative;
            width: 26px;
            height: 40px;
            background: url("http://www.futuregenbiopharm.com/src/icon.png") no-repeat;
            background-position: -104px 0;
          }

          .hide_logo {
            img {
              width: 100px;
              margin-top: 4px;
              -webkit-tap-highlight-color: transparent;
            }
          }
          .hide_taggle {
            position: relative;
            width: 45px;
            font-size: 16px;
            text-align: center;
            cursor: pointer;
            .hide_taggle_box {
              display: none;
              position: absolute;
              top: 110%;
              left: -110%;
              width: 88px;
              border: 1px solid rgba(51, 51, 51, 0.288);
              background-color: #fff;
            }
          }
          .hide_taggle:hover {
            .hide_taggle_box {
              display: block;
            }
          }
        }
      }
      .list_hide {
        position: absolute;
        width: 100vw;
        top: 56px;
        left: 0;
        height: calc(100vh - 56px);
        list-style: none;
        box-sizing: border-box;
        background-color: #203864;
        flex-direction: column;
        padding: 0px 30px;
        overflow-y: scroll;
        li {
          font-size: 22px;
          display: flex;
          flex-direction: column;
          margin-top: 14px;
          text-align: center;
          a {
            padding: 10px 0;
            border-bottom: 1px solid;
            text-decoration: none;
            color: white;
          }
          .nav_two {
            font-size: 15px;
            border: none;
          }
          div {
            top: 90px;
            left: 33%;
            width: 120px;
            background-color: rgba(0, 0, 0, 0.493);
          }
          .hide {
            z-index: 99;
          }
        }
        :last-child a {
          border: none;
        }
        :hover .hide {
          display: block;
        }
      }
    }
    .swiper {
      width: 100%;
      height: 250px;
    }
  }
}
</style>
