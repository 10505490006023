import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
let originPush = VueRouter.prototype.push;
let originReplace = VueRouter.prototype.replace;

Vue.prototype.push = function (location, resolve, reject) {
  if (resolve && reject) {
    originPush.call(this, location, resolve, reject);
  } else {
    originPush.call(
      this,
      location,
      () => {},
      () => {}
    );
  }
};
Vue.prototype.replace = function (location, resolve, reject) {
  if (resolve && reject) {
    originReplace.call(this, location, resolve, reject);
  } else {
    originPush.call(
      this,
      location,
      () => {},
      () => {}
    );
  }
};

let language = localStorage.getItem("language") || "cn";
const titleArr = ["明济生物", "公司文化和荣誉", "发展历程", "公司荣誉", "企业简介", "高管团队", "LOGO 意涵", "产品管线及进展", "产品信息", "平台技术", "投资人关系", "新闻中心", "媒体报道", "职业发展", "联系我们"];
const titleArrEn = [
  "FutureGen",
  "Corporate Culture and Honors",
  "Corporate History",
  "Honor",
  "Corporate Profile",
  "Management Team",
  "LOGO Connotation",
  "Pipeline and Progress",
  "Product Information",
  "Platform Technology",
  "Investor Relations",
  "Media",
  "Media Report",
  "Careers",
  "Contact Us",
];

function getMetaTitle(title) {
  if (language === "cn") {
    return titleArr[title];
  } else {
    return titleArrEn[title];
  }
}
let router = new VueRouter({
  routes: [
    {
      path: "/",
      redirect: "/home",
    },
    {
      path: "/home",
      name: "home",
      component: () => import("@/view/Dashboard"),
      meta: { title: getMetaTitle(0) },
    },
    {
      path: "/about",
      name: "about",
      component: () => import("@/view/About"),
      children: [
        {
          path: "culture",
          name: "culture",
          component: () => import("@/view/About/Culture"),
          meta: { title: getMetaTitle(1) },
        },
        {
          path: "development",
          name: "development",
          component: () => import("@/view/About/Development"),
          meta: { title: getMetaTitle(2) },
        },
        {
          path: "glory",
          name: "glory",
          component: () => import("@/view/About/Glory"),
          meta: { title: getMetaTitle(3) },
        },
        {
          path: "synopsis",
          name: "synopsis",
          component: () => import("@/view/About/Synopsis"),
          meta: { title: getMetaTitle(4) },
        },
        {
          path: "team",
          name: "team",
          component: () => import("@/view/About/Team"),
          meta: { title: getMetaTitle(5) },
        },
        {
          path: "logo",
          name: "logo",
          component: () => import("@/view/About/Logo"),
          meta: { title: getMetaTitle(6) },
        },
      ],
    },
    {
      path: "/product",
      name: "product",
      component: () => import("@/view/Product"),
      children: [
        {
          path: "pipeline",
          name: "pipeline",
          component: () => import("@/view/Product/Pipeline"),
          meta: { title: getMetaTitle(7) },
        },
        {
          path: "information",
          name: "information",
          component: () => import("@/view/Product/Information"),
          meta: { title: getMetaTitle(8) },
        },
      ],
    },
    {
      path: "/platform",
      name: "platform",
      component: () => import("@/view/Platform"),
      children: [
        {
          path: "step",
          name: "step",
          component: () => import("@/view/Platform/Step"),
          meta: { title: getMetaTitle(9) },
        },
        {
          path: "biology",
          name: "biology",
          component: () => import("@/view/Platform/Biology"),
          meta: { title: getMetaTitle(9) },
        },
        {
          path: "cmc",
          name: "cmc",
          component: () => import("@/view/Platform/Cmc"),
          meta: { title: getMetaTitle(9) },
        },
      ],
    },
    {
      path: "/investment",
      name: "investment",
      component: () => import("@/view/Investment"),
      meta: { title: getMetaTitle(10) },
    },
    {
      path: "/new",
      name: "new",
      component: () => import("@/view/New"),
      children: [
        {
          path: "news",
          name: "news",
          component: () => import("@/view/New/News"),
          meta: { title: getMetaTitle(11) },
        },
        {
          path: "newList",
          name: "newList",
          component: () => import("@/view/New/NewList"),
          meta: { title: getMetaTitle(11) },
        },
        {
          path: "Media",
          name: "Media",
          component: () => import("@/view/New/Media"),
          meta: { title: getMetaTitle(12) },
        },
      ],
    },
    {
      path: "/manpower",
      name: "manpower",
      component: () => import("@/view/Manpower"),
      meta: { title: getMetaTitle(13) },
    },
    {
      path: "/contact",
      name: "contact",
      component: () => import("@/view/Contact"),
      meta: { title: getMetaTitle(14) },
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    return { y: 0 };
  },
});
router.afterEach(to => {
  document.title = to.meta.title || "明济生物";
});

export default router;
