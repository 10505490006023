<template>
  <div class="bg">
    <div class="box">
      <ul class="list">
        <li>
          <h3>{{ text1 }}</h3>
          <router-link to="/about/synopsis">{{ text6 }}</router-link>
          <router-link to="/about/development">{{ text5 }}</router-link>
          <router-link to="/about/logo">{{ text26 }}</router-link>
          <router-link to="/about/culture">{{ text3 }}</router-link>
          <router-link to="/about/team">{{ text2 }}</router-link>
        </li>
        <li>
          <h3>{{ text7 }}</h3>
          <router-link to="/product/pipeline">{{ text8 }}</router-link>
        </li>
        <li>
          <h3>{{ text10 }}</h3>
          <router-link to="/platform/step">{{ text11 }}</router-link>
          <router-link to="/platform/biology">{{ text12 }}</router-link>
          <router-link to="/platform/cmc">{{ text13 }}</router-link>
        </li>
        <li>
          <h3>{{ text14 }}</h3>
          <!-- <router-link to="/Investment">{{ text15 }}</router-link> -->
          <!-- <a href="javascript:;">{{ text15 }}</a> -->
        </li>
        <li>
          <h3>{{ text16 }}</h3>
          <router-link to="/new/news">{{ text16 }}</router-link>
        </li>
        <li>
          <h3>{{ text17 }}</h3>
          <router-link to="/manpower">{{ text18 }}</router-link>
          <a href="https://futuregen.gllue.com/portal" target="_blank">{{ text17_3 }}</a>
        </li>
        <li>
          <h3>{{ text19 }}</h3>
          <router-link to="/contact">{{ text19 }}</router-link>
        </li>
      </ul>
      <div class="buttom">
        <p>
          Copyright@ 2020 China All Rights Reserved 版权所有：明济生物制药（北京）有限公司
          <a target="_blank" href="https://beian.miit.gov.cn"><img src="@/assets/img/icon_beian.png" />备案号：京ICP备2022015777号-1</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
let language = localStorage.getItem("language") || "cn";
export default {
  data() {
    switch (language) {
      case "cn":
        return {
          text1: "关于我们",
          text2: "高管团队",
          text3: "企业文化",
          text5: "发展历程",
          text6: "企业简介",
          text7: "产品中心",
          text8: "产品管线及进展",
          text9: "在研产品",
          text10: "平台技术",
          text11: "STEP平台",
          text12: "生物功能分析平台",
          text13: "CMC生产平台",
          text14: "投资人关系",
          text15: "投资人信息披露",
          text16: "新闻中心",
          text17: "职业发展",
          text18: "员工生活",
          text17_3: "招贤纳士",
          text19: "联系我们",
          text26: "LOGO 意涵",
        };
        break;
      case "en":
        return {
          text1: "About Us",
          text2: "Management Team",
          text3: "Corporate Culture",
          text5: "Corporate History",
          text6: "Corporate Profile",
          text7: "Products",
          text8: "Pipeline",
          text9: "Product Information",
          text10: "Platform",
          text11: "STEP Platform",
          text12: "Biological Function Analysis Platform",
          text13: "CMC Production Platform",
          text14: "Investor Relations",
          text15: "Disclosure Information",
          text16: "Media",
          text17: "Careers",
          text18: "Employee Life",
          text17_3: "Job Opportunities",
          text19: "Contact Us",
          text26: "LOGO Connotation",
        };
        break;
    }
  },
  computed: {
    ...mapState(["isLanguage"]),
  },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
a {
  color: #fff;
  text-decoration: none;
}

.bg {
  width: 100vw;
  color: #fff;
  background: #203864;
  .box {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    .list {
      display: flex;
      justify-content: space-between;
      padding-top: 80px;
      margin-bottom: 100px;
      li {
        display: flex;
        flex: 1;
        flex-direction: column;
        margin: 0px 10px;
        h3 {
          font-size: 18px;
          line-height: 1.5;
        }
        a {
          display: block;
          color: rgba(255, 255, 255, 0.7);
          margin-top: 16px;
          font-size: 16px;
        }
      }
    }
    .buttom {
      text-align: center;
      color: rgba(255, 255, 255, 0.6);
      p {
        padding: 20px 0;
        position: relative;
        img {
          width: 18px;
          height: 18px;
        }
      }
      p::after {
        position: absolute;
        top: 10%;
        left: 0;
        content: "";
        width: 100%;
        height: 1px;
        background-color: #ccc;
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .bg {
    padding: 20px;
    box-sizing: border-box;
    background-color: #203864;
    .box {
      .list {
        flex-direction: column;
        margin-top: -70px;
        margin-bottom: 0px;
        li {
          padding-bottom: 10px;
          margin-bottom: 25px;
          border-bottom: 1px solid #ccc;
          a {
            font-size: 14px;
          }
        }
      }
      .buttom {
        font-size: 14px;
        p {
          line-height: 15px;
          font-size: 12px;
        }
        p::after {
          background-color: transparent !important;
        }
      }
    }
  }
}
</style>
